<template>
    <div class="footer-container">
        <div class="footer-items footer-items-1"></div>
        <div class="footer-items footer-items-2"></div>
        <div class="footer-content">
            <div class="footer-items footer-items-1">
                <div class="logo"></div>
                <div class="company-name">中国总代理：广东华正御品健康实业有限公司</div>
                <div class="company-address company-text">
                    地址：广东省梅州市梅县区广梅中路205号
                </div>
                <div class="company-tel company-text">电话：400-6753-001</div>
                <div class="company-email company-text">
                    邮箱：hzyp2021@163.com
                </div>
            </div>
            <div class="footer-items footer-items-2">
                <div class="footer-icon-container">
                    <van-popover v-model="v1" placement="top">
                        <img
                            class="code-image-style"
                            src="../../public/images/wb.png"
                            alt=""
                        />
                        <template #reference>
                            <div
                                class="footer-icon footer-icon-1"
                                @click="v1 = true"
                            ></div>
                        </template>
                    </van-popover>

                    <van-popover v-model="v2" placement="top">
                        <img
                            class="code-image-style"
                            src="../../public/images/wx.jpg"
                            alt=""
                        />
                        <template #reference>
                            <div
                                class="footer-icon footer-icon-2"
                                @click="v2 = true"
                            ></div>
                        </template>
                    </van-popover>

                    <van-popover v-model="v3" placement="top">
                        <img
                            class="code-image-style"
                            src="../../public/images/xhs.png"
                            alt=""
                        />
                        <template #reference>
                            <div
                                class="footer-icon footer-icon-4"
                                @click="v3 = true"
                            ></div>
                        </template>
                    </van-popover>

                    <!-- <div class="footer-icon footer-icon-3"></div> -->
                    <!-- <div class="footer-icon footer-icon-4"></div> -->
                </div>
                <a href="https://beian.miit.gov.cn" target="_blank" class="copy-text">粤ICP备2021042612号</a>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { Popover } from "vant";

Vue.use(Popover);
export default {
    data() {
        return {
            v1: false,
            v2: false,
            v3: false,
        };
    },
};
</script>

<style lang="scss">
.code-image-style {
    display: block;
    padding: 10px;
    width: 100px;
    height: 100px;
}
.footer-container {
    position: relative;
    display: flex;
    height: 200px;
    .copy-text {
        position: absolute;
        right: 0;
        top: 142px;
        font-size: 12px;
        color: white;
    }
    .footer-icon-1 {
        background-image: url(../../public/images/28.png);
    }
    .footer-icon-2 {
        background-image: url(../../public/images/29.png);
    }
    .footer-icon-3 {
        background-image: url(../../public/images/27.png);
    }
    .footer-icon-4 {
        background-image: url(../../public/images/26.png);
    }
    .footer-icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .footer-icon-container {
        display: flex;
        position: absolute;
        top: 42px;
        right: 0;
        justify-content: space-between;
        width: 220px;
        height: 40px;
    }
    .company-text {
        margin-left: 168px;
        margin-top: 10px;
        font-size: 14px;
        color: white;
    }
    .company-address {
        margin-top: 30px;
    }
    .company-name {
        margin-top: 40px;
        margin-left: 168px;
        font-size: 18px;
        font-weight: bold;
        color: white;
    }
    .logo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
        width: 114px;
        height: 114px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/30.png);
    }
    .footer-content {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
        width: 1200px;
        height: 100%;
    }
    .footer-items {
        position: relative;
        width: 50%;
        height: 100%;
    }
    .footer-items-1 {
        background-color: #8f0100;
    }
    .footer-items-2 {
        background-color: #7c0403;
    }
}
</style>