<template>
    <div class="app">
        <HeaderBar type="1" />

        <div class="hide-container">
            <div>
                韩国绿十字生命健康有限公司（Green Cross
                Wellbeing）是国际生物制药领军企业韩国绿十字控股的子公司，致力于提供营养针剂、保健食品、创新药、益生菌和天然新药等全面的健康解决方案，以满足人们不断提高的健康需求，也是正在进行全球首个癌症恶液质创新药物（GCWB204）的研发和多国临床试验的健康护理企业。华正御品红参饮是韩国绿十字生命健康公司根据中国市场需求，结合高端人群养生特点，融合汉代御方在发酵高丽参基础上，加入了枸杞，覆盆子等名贵中药精心调配而成，纯天然成分不含防腐剂，是适合中国高端养生食品。
            </div>
            <div>
                人参自古被尊为百草之王 主补五脏，安精神，定魂魄 止惊悸，除邪气
                明目、开心、益智
            </div>
            <div>
                锦山郡有1500年种植人参历史。那里昼夜温差大，气候条件得天独厚，加工方法独特，贵为皇室御用参
            </div>
            <div>
                汉代御方发酵工艺 融合酶加水分解现代生物科技
                提高确认有免疫增强效果的 人参皂苷Rh2, Rg3成分
            </div>
            <div>
                秉承定制型营养治疗和预防疾病理念，研发搭建了各种个性
                化营养针和健康食品，包括天然成分的医药品和健康食品等
                完善产品体系，以满足人们不断提高的健康需求。
            </div>
            <div>
                成为个人健康护理的领袖
                每个人服用适合自身的养生食品达到肉体和精神的协调，从而享受更加幸福和健康的生活，是我们存在的意义和使命。
                绿十字生命健康提供针对个人设计的Health care
                Solution，希望每个人都能享受健康的生活，我们愿做您生活伴侣。
            </div>
            <div>
                --个人定制型健康管理领域领导者 韩国绿十字生命健康有限公司（Green
                Cross
                Wellbeing）是国际生物制药领军企业韩国绿十字控股的子公司，致力于服务个人定制型健康管理领域。
                独有的PNT (PNT : Personalized Nutrition Therapy)
                个人营养管理系统框架，给顾客提供高品质的健康管理解决方案，最合适的个人定制型营养治疗，达到预防疾病，实现健康高品质生活的目标。
            </div>
            <div>
                华正御品红参饮--发酵高丽参口服液全球第一品牌
                华正御品是韩国绿十字生命健康公司旗下中国市场的红参饮品牌。产品由绿十字生命健康公司生产，原装进口。
                红参饮是华正御品结合高端人群养生特点，融合汉代御方，在发酵高丽参基础上，加入了枸杞，覆盆子等名贵中药精心调配的高端养生饮品，纯天然成分不含防腐剂。
            </div>
            <div>
                愿我们成为您的健康生活伴侣 为每个人提供适合自身的养生食品
                达到肉体和精神上的协调 从而享受更加幸福和健康的生活
                这是我们存在的意义和使命
            </div>
            <div>
                韩国忠清南道锦山郡，千年人参种植地 汉代御方古法红参热处理工艺
                获得极品红参
            </div>
            <a href="https://www.hzyupin.com/details/?id=20">详情页</a>
            <a href="https://www.hzyupin.com/list/">列表页</a>
        </div>

        <div class="swiper-container-index" id="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img
                        class="swiper-slide-pic"
                        src="../../public/images/test.jpg"
                    />
                </div>
                <div class="swiper-slide">
                    <img
                        class="swiper-slide-pic"
                        src="../../public/images/banner_2.jpg"
                    />
                </div>
            </div>

            <div class="swiper-dots-container">
                <div
                    class="swiper-dots-items"
                    :class="{ 'swiper-dots-active': index == swiperIndex }"
                    v-for="(items, index) in 2"
                    :key="index"
                ></div>
            </div>
            <div class="swiper-tips"></div>
            <div id="section-1" class="target-style"></div>
        </div>
        <div class="section-1">
            <div class="section-ctx rect-view">
                <div class="section-1-title wow fadeInUp"></div>
                <div class="icon-1 wow fadeInUp"></div>
                <div class="section-1-2 wow fadeInUp"></div>
                <div class="section-1-3 wow fadeInUp"></div>
                <div class="section-1-4 wow fadeInUp"></div>
            </div>
        </div>
        <div class="section-2" id="section-2">
            <div class="section-ctx rect-view">
                <div class="section-2-1 wow fadeInUp"></div>
                <div class="section-2-2 wow fadeInUp"></div>
                <div id="section-2" class="target-style"></div>
            </div>
        </div>
        <div class="section-3">
            <div class="section-ctx rect-view">
                <div class="section-3-1 wow fadeInUp"></div>
                <div class="section-3-2 wow fadeInUp"></div>
                <div class="section-3-3 wow fadeInUp"></div>
                <div id="section-3" class="target-style"></div>
            </div>
        </div>
        <div class="section-4">
            <div id="section-4" class="target-style"></div>
        </div>
        <div class="section-5" id="section-5">
            <div class="section-ctx rect-view">
                <a
                    href="https://mall.jd.com/index-10250359.html"
                    target="_blank"
                    class="section-5-1 wow fadeInUp"
                    @click="buyRecord(1)"
                ></a>
                <a
                    href="https://item.jd.com/10028144670504.html"
                    target="_blank"
                    class="section-5-2 wow fadeInUp"
                    @click="buyRecord(2)"
                ></a>
                <a
                    href="https://item.jd.com/10021638152595.html"
                    target="_blank"
                    class="section-5-3 wow fadeInUp"
                    @click="buyRecord(3)"
                ></a>
            </div>
            <div id="section-6" class="target-style"></div>
        </div>
        <div class="section-6">
            <div class="section-ctx rect-view" style="overflow: hidden">
                <div class="section-6-1 wow fadeInUp"></div>
                <router-link
                    target="_blank"
                    :to="`/details?id=${list[0].id}`"
                    class="text-container wow fadeInUp"
                    style="margin-top: 256px"
                >
                    <div class="text-items">
                        <div class="news-container">
                            <div class="news-time">
                                新闻稿 {{ list[0]["update_time"] }}
                            </div>
                            <div class="news-title ell-1">
                                {{ list[0]["title"] }}
                            </div>
                            <div class="news-desc ell-3">
                                {{ list[0]["summary"] }}
                            </div>
                            <div class="guide-stlye-1"></div>
                        </div>
                    </div>
                    <div class="text-items">
                        <div class="pic-container">
                            <img
                                :src="list[0]['image']"
                                alt=""
                                class="pic-style"
                            />
                        </div>
                    </div>
                </router-link>

                <div
                    class="text-container wow fadeInUp"
                    style="margin-top: 98px"
                >
                    <router-link
                        target="_blank"
                        class="text-items"
                        :to="`/details?id=${list[1].id}`"
                    >
                        <div class="news-wrap">
                            <div class="news-wrap-pic-container">
                                <img
                                    :src="list[1]['image']"
                                    alt=""
                                    class="pic-style"
                                />
                            </div>
                            <div class="news-text-container">
                                <div class="news-time-1">
                                    新闻稿 {{ list[1]["update_time"] }}
                                </div>
                                <div class="news-title-1 ell-1">
                                    {{ list[1]["title"] }}
                                </div>
                                <div class="news-desc-1 ell-2">
                                    {{ list[1]["summary"] }}
                                </div>
                                <div class="guide-stlye-2"></div>
                            </div>
                        </div>
                    </router-link>
                    <router-link
                        target="_blank"
                        class="text-items"
                        :to="`/details?id=${list[2].id}`"
                    >
                        <div class="news-wrap">
                            <div class="news-wrap-pic-container">
                                <img
                                    :src="list[2]['image']"
                                    alt=""
                                    class="pic-style"
                                />
                            </div>
                            <div
                                class="news-text-container"
                                style="right: -38px"
                            >
                                <div class="news-time-1">
                                    新闻稿 {{ list[2]["update_time"] }}
                                </div>
                                <div class="news-title-1 ell-1">
                                    {{ list[2]["title"] }}
                                </div>
                                <div class="news-desc-1 ell-2">
                                    {{ list[2]["summary"] }}
                                </div>
                                <div class="guide-stlye-2"></div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <router-link target="_blank" class="more-button" to="/list"
                    >了解更多</router-link
                >
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        HeaderBar,
        Footer,
    },
    data() {
        return {
            swiperIndex: 0,
            list: [{}, {}, {}],
        };
    },
    created() {
        _hmt.push(["_trackPageview", "/pc-首页"]);

        $.get("https://www.hzyupin.com/index/News/getlist", (res) => {
            this.list = res.data.data;
        });

        this.$nextTick(() => {
            new Swiper("#swiper-container", {
                effect: "fade",
                speed: 1000,
                autoplay: {
                    delay: 5000,
                },
                autoplay: {
                    disableOnInteraction: false,
                },
                fadeEffect: {
                    crossFade: false,
                },
                on: {
                    slideChange: (swiper) => {
                        this.swiperIndex = swiper.activeIndex;
                    },
                },
            });

            new WOW().init({
                offset: 10,
            });

            if (this.$route.query.target) {
                document
                    .querySelector(`#${this.$route.query.target}`)
                    .scrollIntoView({
                        behavior: "smooth",
                    });
            }
        });
    },
    methods: {
        buyRecord(index) {
            switch (index) {
                case 1:
                    _hmt.push(["_trackEvent", "pc跳转商城", "首页", "首页"]);
                    break;
                case 2:
                    _hmt.push(["_trackEvent", "pc跳转商城", "2880", "2880"]);
                    break;
                case 3:
                    _hmt.push(["_trackEvent", "pc跳转商城", "1180", "1180"]);
                    break;
            }
        },
    },
};
</script>

<style lang="scss">
.hide-container {
    position: fixed;
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
}
.target-style {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 76px;
}
.section-6 {
    position: relative;
    height: 1337px;
    width: 100%;
    background-size: 1920px 1337px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/bg6.jpg);

    .more-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 162px;
        height: 30px;
        font-size: 18px;
        margin: 60px auto 0;
        color: white;
        background-color: black;
        text-decoration: none;
    }

    .guide-stlye-2 {
        position: absolute;
        right: 30px;
        bottom: 20px;
        width: 20px;
        height: 8px;
        transition: all 0.5s;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/25.png);
    }

    .news-desc-1 {
        margin-top: 12px;
        height: 60px;
        color: #0f0101;
        font-size: 14px;
        line-height: 30px;
    }

    .news-title-1 {
        padding-bottom: 16px;
        margin-top: 16px;
        font-size: 20px;
        color: #0f0101;
        font-weight: bold;
        border-bottom: 1px solid #a9b9ba;
    }
    .news-time-1 {
        color: #0f0101;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.7;
    }

    .news-text-container {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 296px;
        height: 208px;
        padding: 20px 30px;
        box-sizing: border-box;
        background-color: white;
    }

    .news-wrap-pic-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 482px;
        height: 322px;
        overflow: hidden;
    }

    .news-wrap {
        position: relative;
        height: 420px;
        width: 562px;
    }

    .guide-stlye-1 {
        margin-top: 40px;
        margin-left: 472px;
        width: 43px;
        height: 10px;
        transition: all 0.5s;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/24.png);
    }

    .news-desc {
        position: relative;
        margin-top: 30px;
        font-size: 16px;
        color: #0f0101;
        line-height: 34px;
    }

    .news-container {
        width: 516px;
        // overflow: hidden;
    }

    .news-title {
        margin-top: 16px;
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
        color: #0f0101;
        border-bottom: 1px solid #a9b9ba;
    }

    .news-time {
        margin-top: 132px;
        font-size: 14px;
        color: #0f0101;
    }

    .pic-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 412px;
        overflow: hidden;
    }
    .pic-style {
        display: block;
        width: 100%;
        transition: all 0.5s;
    }
    .text-container {
        display: flex;
        cursor: pointer;
    }
    .text-items {
        width: 50%;
        &:hover {
            .guide-stlye-1,
            .guide-stlye-2 {
                transform: translate3d(6px, 0, 0);
            }
            .pic-style {
                transform: scale(1.05);
            }
        }
    }
    .section-6-1 {
        position: absolute;
        left: 0;
        top: 74px;
        width: 1202px;
        height: 127px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/23.png);
    }
}
.section-5 {
    position: relative;
    height: 1080px;
    width: 100%;
    background-size: 1920px 1080px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/bg5.jpg);
    .section-5-1 {
        position: absolute;
        left: 0;
        top: 232px;
        width: 461px;
        height: 707px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/22.jpg);
        transition: all 0.5s;
        &:hover {
            transform: scale(1.05);
            z-index: 10;
        }
    }
    .section-5-2 {
        position: absolute;
        right: 0;
        top: 232px;
        width: 734px;
        height: 351px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/21.jpg);
        transition: all 0.5s;
        &:hover {
            transform: scale(1.05);
            z-index: 10;
        }
    }
    .section-5-3 {
        position: absolute;
        right: 0;
        top: 588px;
        width: 734px;
        height: 351px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/20.jpg);
        transition: all 0.5s;
        &:hover {
            transform: scale(1.05);
            z-index: 10;
        }
    }
}
.section-4 {
    position: relative;
    height: 1004px;
    width: 100%;
    background-size: 1920px 1004px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/bg4.jpg);
}

.section-3 {
    position: relative;
    height: 1568px;
    width: 100%;
    background-size: 1920px 1568px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/bg3.jpg);
    .section-3-3 {
        position: absolute;
        bottom: 458px;
        right: 0;
        width: 399px;
        height: 265px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/19.png);
    }
    .section-3-1 {
        position: absolute;
        top: 130px;
        left: 0;
        width: 372px;
        height: 230px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/17.png);
    }
    .section-3-2 {
        position: absolute;
        top: 288px;
        left: 398px;
        width: 404px;
        height: 403px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/18.png);
    }
}
.section-2 {
    position: relative;
    height: 592px;
    width: 100%;
    background-size: 1920px 592px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/bg2.jpg);
    .section-2-1 {
        position: absolute;
        top: 136px;
        left: 0;
        width: 353px;
        height: 222px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/15.png);
    }
    .section-2-2 {
        position: absolute;
        bottom: -202px;
        right: 122px;
        width: 417px;
        height: 660px;
        z-index: 1;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/16.png);
    }
}
.section-1 {
    position: relative;
    height: 1006px;
    width: 100%;
    background-size: 1920px 1006px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/bg1.jpg);
    .section-1-4 {
        position: absolute;
        bottom: 306px;
        right: 16px;
        width: 164px;
        height: 150px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/13.png);
    }
    .section-1-3 {
        position: absolute;
        top: 302px;
        right: 58px;
        width: 541px;
        height: 259px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/12.png);
    }
    .section-1-2 {
        position: absolute;
        bottom: 0;
        left: 74px;
        width: 409px;
        height: 629px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/14.png);
    }
    .icon-1 {
        position: absolute;
        top: 280px;
        left: -98px;
        right: 0;
        width: 125px;
        height: 140px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/11.png);
    }
    .section-1-title {
        position: absolute;
        top: 76px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1201px;
        height: 127px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/10.png);
    }
}
.swiper-container-index {
    // height: 100vh;
    // max-height: 1080px;
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    .swiper-wrapper,
    .swiper-slide {
        position: relative;
        overflow: hidden;
    }
    .swiper-dots-container {
        position: absolute;
        display: flex;
        top: 0;
        right: 74px;
        height: 100%;
        z-index: 10;
        justify-content: center;
        flex-direction: column;
    }
    .swiper-dots-items {
        width: 18px;
        height: 18px;
        margin: 10px 0;
        opacity: 0.6;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/6.png);
    }
    .swiper-dots-active {
        opacity: 1;
    }
    .swiper-slide-pic {
        display: block;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // margin-left: -960px;
        // margin-top: -540px;
        width: 100%;
        // height: 1080px;
        transform: scale(1.02);
        transition: all 5s;
    }
    .swiper-slide-active {
        .swiper-slide-pic {
            transform: scale(1);
        }
    }

    .swiper-tips {
        position: absolute;
        top: 90vh;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 48px;
        height: 71px;
        z-index: 10;
        animation: 1.2s ease-in-out infinite btnPage;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/9.png);
    }

    @keyframes btnPage {
        0%,
        100% {
            transform: translateY(6px);
        }
        50% {
            transform: translateY(0);
        }
    }
}
</style>