<template>
    <div class="header-bar" :class="{ 'header-bar-move': isMove }">
        <div
            class="header-container"
            @mouseover="openState = true"
            @mouseleave="openState = false"
        >
            <div class="menus-logo-container">
                <div class="logo" :class="{ 'logo-change': logoChange }"></div>
                <div
                    class="menus-btn"
                    :class="{ 'menus-btn-active': openState }"
                ></div>

                <div class="menus-text">MENU</div>

                <transition name="fade">
                    <div class="menus-container" v-if="openState">
                        <div class="menus-list-container">
                            <template v-if="type == 1">
                                <div
                                    @click="toScrollTop('swiper-container')"
                                    class="menus-items"
                                >
                                    首页
                                </div>
                                <div
                                    @click="toScrollTop('section-1')"
                                    class="menus-items"
                                >
                                    御参说
                                </div>
                                <div
                                    @click="toScrollTop('section-5')"
                                    class="menus-items"
                                >
                                    御品中心
                                </div>
                                <!-- <div
                                    @click="toScrollTop('section-6')"
                                    class="menus-items"
                                >
                                    颐养天年
                                </div> -->
                                <router-link
                                    target="_blank"
                                    class="menus-items"
                                    to="/list"
                                    >颐养天年</router-link
                                >
                                <router-link
                                    target="_blank"
                                    class="menus-items"
                                    to="/about"
                                    >关于我们</router-link
                                >
                            </template>
                            <template v-else>
                                <router-link
                                    to="/index?target=swiper-container"
                                    class="menus-items"
                                >
                                    首页
                                </router-link>
                                <router-link
                                    to="/index?target=section-1"
                                    class="menus-items"
                                >
                                    御参说
                                </router-link>
                                <router-link
                                    to="/index?target=section-5"
                                    class="menus-items"
                                >
                                    御品中心
                                </router-link>
                                <router-link
                                    to="/list"
                                    class="menus-items"
                                >
                                    颐养天年
                                </router-link>

                                <router-link to="/about" class="menus-items">
                                    关于我们
                                </router-link>
                            </template>
                        </div>
                    </div>
                </transition>
            </div>

            <a
                href="https://mall.jd.com/index-10250359.html?from=pc"
                target="_blank"
                class="home-button public-button"
            ></a>
            <!-- <div class="search-button public-button"></div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ["type", "lock", "logoChange"],
    data() {
        return {
            openState: this.type == 1 ? true : false,
            isMove: false,
        };
    },
    created() {
        if (!this.lock) {
            let ctx = $(window);
            ctx.on("scroll", () => {
                // $(window).scrollTop();
                let scrollTop = ctx.scrollTop();

                if (!scrollTop) {
                    this.isMove = false;
                    // if (this.type == 1) {
                    //     this.openState = true;
                    // }
                } else {
                    this.isMove = true;
                }
            });
        }
    },
    methods: {
        toScrollTop(name) {
            // console.log(name);
            document.querySelector(`#${name}`).scrollIntoView({
                behavior: "smooth",
            });
        },
    },
};
</script>

<style lang="scss">
.header-container {
    position: relative;
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
}

.menus-logo-container {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 20px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
.header-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 76px;
    width: 100%;
    z-index: 20;
    transition: all 0.5s;

    .public-button {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 42px;
        height: 42px;
        cursor: pointer;
        background-size: 100%;
        background-repeat: no-repeat;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.1);
        }
    }

    .home-button {
        // right: 132px;
        right: 20px;
        background-image: url(../../public/images/8.png);
    }
    .search-button {
        right: 20px;
        background-image: url(../../public/images/7.png);
    }

    .menus-text {
        display: flex;
        // position: absolute;
        // top: 0;
        // left: 132px;
        margin-left: 22px;
        height: 100%;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: white;
        cursor: pointer;
        transition: all 0.5s;
    }

    .menus-items {
        margin-right: 64px;
        cursor: pointer;
        color: white;
        transition: all 0.5s;
        text-decoration: none;
        &:hover {
            transform: scale(1.1);
        }
    }

    .menus-container {
        // position: absolute;
        // top: 0;
        // left: 230px;
        margin-left: 68px;
        height: 100%;
    }

    .menus-list-container {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .logo {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        width: 42px;
        height: 43px;
        // margin: auto 0;
        transition: all 0.5s;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/1.png);
    }
    .logo-change {
        background-image: url(../../public/images/logo1.png);
    }
    .menus-btn {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        left: 92px;
        margin-left: 52px;
        // margin: auto 0;
        width: 22px;
        height: 22px;
        cursor: pointer;
        transition: all 0.5s;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/2.png);
    }
    .menus-btn-active {
        background-image: url(../../public/images/3.png);
    }
}
.header-bar-move {
    background-color: #4b0303;
    .menus-btn {
        background-image: url(../../public/images/4.png);
    }
    .menus-btn-active {
        background-image: url(../../public/images/5.png);
    }
    .menus-items,
    .menus-text {
        color: #dea864;
    }
    .logo-change {
        background-image: url(../../public/images/1.png);
    }
}
</style>